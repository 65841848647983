body {
  min-width: 320px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-tel-input .special-label{
  display: block !important;
  top: -10px !important;
  left: 10px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 12px !important;

}
.ReactCrop__image{
  max-height: 550px;
}
.ck-editor__editable_inline{
  height: 40vh;
}